import { memo, useCallback } from "react"
import { Box, Text, HStack, Image, VStack } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { ProductPrice } from "@components/Product/ProductPrice"
import { useCart } from "@app/hooks/useCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { CustomIcon } from "@components/Icon"
import { QuantitySelector } from "@components/QuantitySelector"
import { BrandedProductTitle } from "@components/BrandedProductTitle"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useSubscriptions } from "@app/hooks/useSubscriptions"

import type { desktopColumns } from "./CartList"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type CartItemMainProps = {
  item: CartLine
  columns: typeof desktopColumns
}

type ProductItemProps = {
  item: CartLine
  width: string
  isGift: boolean
  mutationLoading?: boolean
}

const ProductItemImageTitleRemove: React.FC<ProductItemProps> = ({ item, width, mutationLoading }) => {
  const {
    settings: { routes },
    store: { TEMP_CONTROL_POUCH_ID },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { removeFromCart, loading, updateItem } = useCart()
  const locales = useLocale(LOCALE_KEYS.CART)

  const giftAttributes = item?.attributes?.find(
    (field: any) => field.key === "isGift" || field.key === "_isSample" || field.key === "_isSamplePlaceholder"
  )

  const isGift = !!giftAttributes?.value
  const giftMessage = isGift && item?.attributes?.find((field: any) => field.key === "_giftMessage")?.value
  const itemVariantId = item?.attributes?.find((field: any) => field.key === "_variant_id")?.value
  const isTempControllPouch = itemVariantId === TEMP_CONTROL_POUCH_ID

  const removeTempControlledPouch = (itemId: string, variantId: string) => {
    window?.sessionStorage?.setItem("temp-controlled-pouch-removed", "true")
    removeFromCart(itemId, variantId)
  }

  const hasImage = !!item?.merchandise?.image?.url
  const hasTitle = !!item?.merchandise?.title

  const oneTimeOrSubscription = item?.attributes?.find((field: any) => field.key === "_oneTimeOrSubscription")?.value

  const subscriptionDiscountPercent = item?.attributes?.find((field: any) => field.key === "_subscriptionDiscount")?.value

  const {
    subscriptionMessages: { oneTimeProductMessage, subscriptionProductMessage },
  } = useSubscriptions()

  const removeUi = (
    <HStack
      spacing={2.5}
      cursor="pointer"
      onClick={
        loading || mutationLoading
          ? () => null
          : isTempControllPouch
          ? () => removeTempControlledPouch(item?.id, item?.merchandise?.id)
          : () => removeFromCart(item?.id, item?.merchandise?.id)
      }
      _hover={{
        cursor: mutationLoading ? "not-allowed" : "pointer",
        pointEvents: mutationLoading ? "none" : "all",
      }}
      role="group"
    >
      <CustomIcon name="trash" width={12} cursor="pointer" title="Remove" />
      {locales?.cartRemoveProductLabel && (
        <Text borderBottom="1px" _groupHover={{ borderBottomColor: "transparent" }}>
          {locales?.cartRemoveProductLabel}
        </Text>
      )}
    </HStack>
  )

  const handleConvertToSubscription = useCallback(async () => {
    await updateItem(item?.id, item?.merchandise?.id, item?.quantity, [
      { key: "_oneTimeOrSubscription", value: "monthly" },
      { key: "_subscriptionDiscount", value: subscriptionDiscountPercent },
    ])
  }, [item?.id, item?.quantity, item?.merchandise?.id, subscriptionDiscountPercent, updateItem])

  return (
    <HStack alignItems="stretch" width={width}>
      {hasImage && <Image width={18} src={item?.merchandise?.image?.url} />}
      <VStack alignItems="flex-start" justifyContent="space-between" w="full">
        <Box>
          {hasTitle &&
            (isGift ? (
              <Text fontSize="md">
                <BrandedProductTitle item={item?.merchandise?.product} />
              </Text>
            ) : (
              <CustomLink to={urlResolver(item?.merchandise?.product, routes.PRODUCT)?.url} variant="no-underline">
                <Text fontSize="md">
                  <BrandedProductTitle item={item?.merchandise?.product} />
                </Text>
              </CustomLink>
            ))}

          {oneTimeOrSubscription ? (
            oneTimeOrSubscription === "one-time" ? (
              <Text fontSize="sm" mt="2" color="brand.lighterGreen" fontWeight="bold">
                <Text
                  as="button"
                  fontSize="sm"
                  color="brand.lighterGreen"
                  fontWeight="bold"
                  textDecoration="underline"
                  onClick={handleConvertToSubscription}
                >
                  Subscribe
                </Text>
                {oneTimeProductMessage.replace("{{X%}}", `${subscriptionDiscountPercent}%`).replace("{{Subscribe}}", "")}
              </Text>
            ) : (
              <Text fontSize="sm" color="brand.lighterGreen" fontWeight="bold">
                {subscriptionProductMessage.replace("{{X%}}", `${subscriptionDiscountPercent}%`)}
              </Text>
            )
          ) : null}
        </Box>
        {!isGift && removeUi}
        {isGift && giftMessage && (
          <Text color="brand.lighterGreen" fontWeight="bold" fontStyle="italic" _groupHover={{ borderBottomColor: "transparent" }}>
            {giftMessage}
          </Text>
        )}
        {isTempControllPouch && removeUi}
      </VStack>
    </HStack>
  )
}

const ProductItemPrice: React.FC<ProductItemProps> = ({ item, width }) => {
  const oneTimeOrSubscription = item?.attributes?.find((field: any) => field.key === "_oneTimeOrSubscription")?.value

  const subscriptionDiscountPercent = item?.attributes?.find((field: any) => field.key === "_subscriptionDiscount")?.value

  return (
    <Box width={width} alignSelf="center">
      <ProductPrice
        variant={item?.merchandise}
        type="cartMini"
        appliedDiscount={oneTimeOrSubscription && oneTimeOrSubscription === "monthly" ? subscriptionDiscountPercent : 0}
      />
    </Box>
  )
}

const ProductItemQuantity: React.FC<ProductItemProps> = ({ item, loading, width, isGift, mutationLoading }) => {
  return (
    <Box width={width} alignSelf="center">
      {!isGift && <QuantitySelector line={item} variant="small" isDisabled={!!loading} mutationLoading={mutationLoading} width={20} />}
    </Box>
  )
}

const ProductItemTotal: React.FC<ProductItemProps> = ({ item, width }) => {
  const oneTimeOrSubscription = item?.attributes?.find((field: any) => field.key === "_oneTimeOrSubscription")?.value

  const subscriptionDiscountPercent = item?.attributes?.find((field: any) => field.key === "_subscriptionDiscount")?.value

  return (
    <Box width={width} textAlign="right" alignSelf="center">
      <ProductPrice
        variant={item?.merchandise}
        type="cartMini"
        quantity={item?.quantity}
        totalOnly
        appliedDiscount={oneTimeOrSubscription && oneTimeOrSubscription === "monthly" ? subscriptionDiscountPercent : 0}
      />
    </Box>
  )
}

const mappings = {
  product: ProductItemImageTitleRemove,
  price: ProductItemPrice,
  quantity: ProductItemQuantity,
  total: ProductItemTotal,
}

type mappingsKey = keyof typeof mappings

const CartItemMain: React.FC<CartItemMainProps> = ({ item, columns }) => {
  const { loading: cartLoading, mutationLoading } = useCart()
  const loading = cartLoading
  const giftAttributes = item?.attributes?.find(
    (field: any) =>
      field.key === "isGift" || field.key === "_isSample" || field.key === "_isSamplePlaceholder" || field.key === "_GiftMessage"
  )
  const isGift = !!giftAttributes?.value
  // const giftMessage = isGift && item?.attributes?.find((field: any) => field.key === "giftMessage")?.value

  return (
    <Box borderBottom="1px" py={6}>
      <HStack alignItems="stretch" spacing={4}>
        {columns?.map(({ title, width }, idx) => {
          const Item: React.FC<any> = mappings[title as mappingsKey]

          return Item ? (
            <Item width={width} item={item} loading={loading} mutationLoading={mutationLoading} isGift={isGift} key={`${title}-${idx}`} />
          ) : null
        })}
      </HStack>
    </Box>
  )
}

const MemoCartItemMain = memo(CartItemMain)
export { MemoCartItemMain as CartItemMain }
