import { memo, useCallback } from "react"
import { Box, Text, HStack, Image, VStack } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { ProductPrice } from "@components/Product/ProductPrice"
import { useCart } from "@app/hooks/useCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { CustomIcon } from "@components/Icon"
import { QuantitySelector } from "@components/QuantitySelector"
import { BrandedProductTitle } from "@components/BrandedProductTitle"
import { useSubscriptions } from "@app/hooks/useSubscriptions"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type CartItemMiniProps = {
  item: CartLine
  renderedFromMain?: boolean
}

const CartItemMini: React.FC<CartItemMiniProps> = ({ item, renderedFromMain = false }) => {
  const {
    settings: { routes },
    store: { TEMP_CONTROL_POUCH_ID },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { loading: cartLoading, removeFromCart, mutationLoading, updateItem } = useCart()
  const loading = cartLoading

  const hasTitle = !!item?.merchandise?.product?.title
  const hasImage = !!item?.merchandise?.image?.url

  const giftAttributes = item?.attributes?.find(
    (field: any) => field.key === "isGift" || field.key === "_isSample" || field.key === "_isSamplePlaceholder"
  )
  const isGift = !!giftAttributes?.value
  const giftMessage = isGift && item?.attributes?.find((field: any) => field.key === "_giftMessage")?.value
  const isGiftMessage = !!item?.attributes?.find((field: any) => field.key === "_GiftMessage")?.value
  const itemVariantId = item?.attributes?.find((field: any) => field.key === "_variant_id")?.value
  const isTempControllPouch = itemVariantId === TEMP_CONTROL_POUCH_ID

  const removeTempControlledPouch = (itemId: string, variantId: string) => {
    window?.sessionStorage?.setItem("temp-controlled-pouch-removed", "true")
    removeFromCart(itemId, variantId)
  }

  const oneTimeOrSubscription = item?.attributes?.find((field: any) => field.key === "_oneTimeOrSubscription")?.value

  const subscriptionDiscountPercent = item?.attributes?.find((field: any) => field.key === "_subscriptionDiscount")?.value

  const {
    subscriptionMessages: { oneTimeProductMessage, subscriptionProductMessage },
  } = useSubscriptions()

  const removeUi = (
    <Box
      cursor="pointer"
      onClick={
        loading || mutationLoading
          ? () => null
          : isTempControllPouch
          ? () => removeTempControlledPouch(item?.id, item?.merchandise?.id)
          : () => removeFromCart(item?.id, item?.merchandise?.id)
      }
      _hover={{
        cursor: mutationLoading ? "not-allowed" : "pointer",
        pointEvents: mutationLoading ? "none" : "all",
      }}
    >
      <CustomIcon name="trash" width={12} cursor="pointer" title="Remove" />
    </Box>
  )

  const handleConvertToSubscription = useCallback(async () => {
    await updateItem(item?.id, item?.merchandise?.id, item?.quantity, [
      { key: "_oneTimeOrSubscription", value: "monthly" },
      { key: "_subscriptionDiscount", value: subscriptionDiscountPercent },
    ])
  }, [item?.id, item?.quantity, item?.merchandise?.id, subscriptionDiscountPercent, updateItem])

  return (
    <Box borderBottom="1px" py={6}>
      <HStack alignItems="stretch" spacing={4}>
        {hasImage && <Image width={[20, 18]} height={[20, 18]} src={item?.merchandise?.image?.url} />}
        <VStack alignItems="flex-start" justifyContent="space-between" w="full" spacing={[5, 3.5]}>
          <HStack spacing={5} justifyContent="space-between" w="full" alignItems="flex-start">
            <Box>
              {hasTitle &&
                (isGift ? (
                  <Text fontSize={["sm", "md"]}>
                    <BrandedProductTitle item={item?.merchandise?.product} />
                  </Text>
                ) : (
                  <CustomLink to={urlResolver(item?.merchandise?.product, routes.PRODUCT)?.url} variant="no-underline">
                    <Text fontSize={["sm", "md"]}>
                      <BrandedProductTitle item={item?.merchandise?.product} />
                    </Text>
                  </CustomLink>
                ))}
              {oneTimeOrSubscription ? (
                oneTimeOrSubscription === "one-time" ? (
                  <Text fontSize="sm" color="brand.lighterGreen" fontWeight="bold" marginTop="2">
                    <Text
                      as="button"
                      fontSize="sm"
                      color="brand.lighterGreen"
                      fontWeight="bold"
                      textDecoration="underline"
                      onClick={handleConvertToSubscription}
                    >
                      Subscribe
                    </Text>
                    {oneTimeProductMessage.replace("{{X%}}", `${subscriptionDiscountPercent}%`).replace("{{Subscribe}}", "")}
                  </Text>
                ) : (
                  <Text fontSize="sm" color="brand.lighterGreen" fontWeight="bold" marginTop="2">
                    {subscriptionProductMessage.replace("{{X%}}", `${subscriptionDiscountPercent}%`)}
                  </Text>
                )
              ) : null}
            </Box>
            {(!isGift || isTempControllPouch) && removeUi}
          </HStack>

          <HStack alignItems="flex-end" w="full" justifyContent="space-between">
            {/* <Button variant="link" isDisabled={loading} onClick={handleMove}>
              Move to Shopping List
            </Button> */}
            {!isGift && !isGiftMessage && (
              <QuantitySelector line={item} variant="small" isDisabled={!!loading} mutationLoading={mutationLoading} />
            )}
            {isGift && giftMessage && renderedFromMain && (
              <Text color="brand.lighterGreen" fontWeight="bold" fontStyle="italic" _groupHover={{ borderBottomColor: "transparent" }}>
                {giftMessage}
              </Text>
            )}

            <Box>
              <ProductPrice
                variant={item?.merchandise}
                type="cartMini"
                quantity={item?.quantity}
                appliedDiscount={oneTimeOrSubscription && oneTimeOrSubscription === "monthly" ? subscriptionDiscountPercent : 0}
              />
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  )
}

const MemoCartItemMini = memo(CartItemMini)
export { MemoCartItemMini as CartItemMini }
