import { gql } from "@apollo/client"

import { CART_FRAGMENT } from "@app/graphql/fragments/cartFragment"
import { DELIVERY_OPTION_FRAGMENT } from "@app/graphql/fragments/deliveryFragment"
import { MAILING_ADDRESS_FRAGMENT } from "@app/graphql/fragments/mailingAddressFragment"

export const GET_CART = gql`
  query GET_CART($cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cart(id: $cartId) {
      ... on Cart {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`

export const GET_SHIPPING_RATES = gql`
  query GET_SHIPPING_RATES($cartId: ID!) @inContext(country: $countryCode) {
    cart(id: $cartId) {
      ... on Cart {
        deliveryGroups(first: 10) {
          edges {
            node {
              deliveryAddress {
                ...MailingAddressFragment
              }
              deliveryOptions {
                ...DeliveryOptionFragment
              }
              selectedDeliveryOption {
                ...DeliveryOptionFragment
              }
            }
          }
        }
      }
    }
  }
  ${DELIVERY_OPTION_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
`

export const GET_PRODUCTS_BY_HANDLE = gql`
  query getProductsByHandle(
    $handles: [String!]!
    $countryCode: CountryCode!
    $firstCollections: Int!
    $firstImages: Int!
    $firstMedia: Int!
    $firstMetafields: Int!
    $firstVariants: Int!
    $metafieldIdentifiers: [MetafieldIdentifierInput!]
  ) {
    nodes(ids: $handles, country: $countryCode) {
      ... on Product {
        handle
        variants(first: $firstVariants) {
          edges {
            node {
              id
              sku
            }
          }
        }
      }
    }
  }
`
