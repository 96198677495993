import { memo, useCallback } from "react"
import { HStack, Button, Box, Spinner, NumberInput, NumberInputField } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type QuantitySelectorProps = {
  width?: any
  line?: CartLine
  variant?: string
  isDisabled?: boolean
  height?: number | Array<number>
  mutationLoading?: boolean
  [rest: string]: any
}

// product variant = shopify product variant
// variant = chakra stye variant
const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  width,
  line,
  variant,
  isDisabled = false,
  height,
  mutationLoading,
  ...rest
}) => {
  const { updateQuantity, loading, removeFromCart } = useCart()

  const handleCartIncrement = useCallback(() => {
    updateQuantity(line?.id, line?.merchandise?.id, line?.quantity + 1)
  }, [line?.id, line?.quantity, line?.merchandise?.id, updateQuantity])

  const handleCartDecrement = useCallback(() => {
    if (line?.quantity === 1) {
      removeFromCart(line?.id, line?.merchandise?.id)
    } else {
      updateQuantity(line?.id, line?.merchandise?.id, line?.quantity - 1)
    }
  }, [line?.id, line?.quantity, line?.merchandise?.id, removeFromCart, updateQuantity])

  const isSmallVariant = variant === "small"

  if (isDisabled) return <Spinner />

  const containerHeight = height || (isSmallVariant ? 6 : 10)

  return (
    <HStack spacing={0} alignItems="stretch" mb={isSmallVariant ? 0 : [0, 2, 2]} w={width} height={containerHeight} {...rest}>
      <Button
        minW={0}
        w={isSmallVariant ? 6 : 12}
        p={0}
        borderLeftRadius="base"
        borderRightRadius="none"
        borderRight={isSmallVariant ? "unset" : "base"}
        onClick={handleCartDecrement}
        isDisabled={mutationLoading}
        variant={isSmallVariant ? "outline" : "solid"}
        h="full"
      >
        -
      </Button>
      <Box
        flexGrow={1}
        textAlign="center"
        bg={isSmallVariant ? "brand.offWhite" : "brand.lightSand"}
        color={isSmallVariant ? "brand.avocado" : "brand.offWhite"}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        borderY="1px"
        borderColor={isSmallVariant ? "brand.avocado" : "brand.sand"}
        w={isSmallVariant ? 6 : "unset"}
        // prevent from clicking parent link
        onClick={e => e.stopPropagation()}
      >
        {loading ? (
          <Spinner h={isSmallVariant ? 2.5 : 6} w={isSmallVariant ? 2.5 : 6} color="brand.avocado" />
        ) : (
          <NumberInput defaultValue={line?.quantity} min={0} max={100} w="100%" p="0">
            <NumberInputField
              color="brand.avocado"
              textAlign="center"
              w="100%"
              p="0"
              border="none"
              _focus={{ border: "none" }}
              _focusVisible={{ border: "none" }}
              _active={{ border: "none" }}
              onBlur={({ target: { value } }) => {
                if (value) {
                  updateQuantity(line?.id, line?.merchandise?.id, parseInt(value))
                } else {
                  removeFromCart(line?.id, line?.merchandise?.id)
                }
              }}
            />
          </NumberInput>
        )}
      </Box>
      <Button
        h="full"
        minW={0}
        w={isSmallVariant ? 6 : 12}
        p={0}
        borderRightRadius="base"
        borderLeftRadius="none"
        onClick={handleCartIncrement}
        isDisabled={mutationLoading}
        borderLeft={isSmallVariant ? "unset" : "base"}
        variant={isSmallVariant ? "outline" : "solid"}
      >
        +
      </Button>
    </HStack>
  )
}

const MemoQuantitySelector = memo(QuantitySelector)
export { MemoQuantitySelector as QuantitySelector }
