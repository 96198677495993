import { useMemo, createContext, useContext, useEffect, useCallback, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import { useFunctions } from "@app/hooks/useFunctions"
import { useCore } from "@app/hooks/useCore"
import { useStorage } from "@app/hooks/useCore"
import { useCustomerContext } from "@app/providers/customer"

import type { Customer } from "shopify-storefront-api-typings"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type ContextProps = {
  checkIfSubscriptionProduct: ({ productId }: { productId: string }) => { isSubscriptionProduct: boolean }
  fetched: boolean
  subscriptionProducts: Array<any>
  goToRechargeCheckout: ({ lineItems }?: { lineItems: Array<any> }) => void
  processRechargeCheckout: ({
    lineItems,
    isSubscriptionProduct,
    isMainCart,
    isForced,
  }: {
    lineItems: CartLine[] | undefined
    isSubscriptionProduct?: boolean
    isMainCart?: boolean
    isForced?: boolean
  }) => { shouldGoToNormalCheckout: boolean }
  portalUrl: string
  checkSubscriptionText: (text?: string) => boolean
}

const SubscriptionContext = createContext<ContextProps | undefined>(undefined)

const SubscriptionProvider: React.FC = ({ children }) => {
  const {
    settings: { functions, keys },
  } = useConfigContext()
  const { getStorage, setStorage } = useStorage()
  const { store } = useConfigContext()
  const {
    helpers: { decodeShopifyId, isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()

  const [subscriptionProducts, setSubscriptionProducts] = useState<any>([])
  const [fetched, setFetched] = useState<boolean>(false)
  const [portalUrl, setPortalUrl] = useState("")
  const [pendingCheckout, setPendingCheckout] = useState<boolean>(false)

  const getSubscriptionProducts = useCallback(async () => {
    const storageSubscriptionProducts = getStorage(keys.subscriptionProducts)
    if (!fetched && storageSubscriptionProducts) {
      setSubscriptionProducts(storageSubscriptionProducts)
    }

    if (!fetched && !storageSubscriptionProducts) {
      try {
        const { status, body } = await callFunction(functions.rechargeGetSubscriptionProducts, null, "GET")
        if (status === "error") throw new Error("Error calling the recharge get subscription products endpoint")
        setSubscriptionProducts(body)
        const ONE_HOUR = 0.0412
        setStorage(keys.subscriptionProducts, body, ONE_HOUR)
      } catch (err) {
        console.warn("Error fetching subscription products", err)
      }
    }
    setFetched(true)
  }, [callFunction, fetched, functions, getStorage, keys, setStorage])

  const normaliseRechargeLineItem = useCallback(
    (lineItem: any) => {
      const extraSubscriptionValues: any = {}
      if (lineItem?.productId) {
        const intervalUnitMappings = {
          Months: "month",
          month: "month",
          Weeks: "week",
          week: "week",
          Days: "day",
          day: "day",
        }
        const lineItemsSubscriptionProduct = subscriptionProducts?.find(
          (subscriptionProduct: any) => subscriptionProduct?.product_id === lineItem?.productId
        )

        if (lineItemsSubscriptionProduct) {
          // Exclude one-off products from the subscription values otherwise Recharge will consider them
          // as monthly & discount them...
          const isOneOffProduct = lineItem.attributes.find(
            customAttribute => customAttribute.key === "_oneTimeOrSubscription" && customAttribute.value === "one-time"
          )

          if (!isOneOffProduct) {
            Object.assign(extraSubscriptionValues, {
              order_interval_frequency: Number(lineItemsSubscriptionProduct?.subscription_defaults?.order_interval_frequency_options[0]),
              charge_interval_frequency: lineItemsSubscriptionProduct?.subscription_defaults?.charge_interval_frequency,
              order_interval_unit:
                intervalUnitMappings?.[
                  lineItemsSubscriptionProduct?.subscription_defaults?.order_interval_unit as keyof typeof intervalUnitMappings
                ],
              cutoff_day_of_month: lineItemsSubscriptionProduct?.subscription_defaults?.cutoff_day_of_month,
              order_day_of_month: lineItemsSubscriptionProduct?.subscription_defaults?.order_day_of_month,
              expire_after_specific_number_of_charges:
                lineItemsSubscriptionProduct?.subscription_defaults?.expire_after_specific_number_of_charges,
            })
          }
        }
      }

      return {
        quantity: lineItem?.quantity,
        variant_id: Number(decodeShopifyId(lineItem?.variantId, "ProductVariant")),
        properties: lineItem?.attributes?.map(({ key, value }) => ({ name: key, value })),
        product_id: lineItem?.productId,
        ...extraSubscriptionValues,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [decodeShopifyId, subscriptionProducts?.length]
  )

  const checkIfSubscriptionProduct = useCallback(
    ({ productId }: { productId: string }) => {
      const isSubscriptionProduct = subscriptionProducts?.find((product: any) => {
        return product?.product_id === Number(productId)
      })
      return { isSubscriptionProduct }
    },
    [subscriptionProducts]
  )

  const goToRechargeCheckout = useCallback(
    async ({ lineItems }) => {
      const rechargeLineItems: { line_items: any } = {
        line_items: lineItems,
      }

      try {
        if (!rechargeLineItems.line_items) throw new Error("No line items found.")

        const { status, body } = await callFunction(functions.rechargeCreateCheckout, {
          lineItems: rechargeLineItems,
        })

        if (status === "error") throw new Error("Error calling the recharge get create checkout endpoint")

        if (body?.token) {
          const rechargeCheckoutUrl = `https://checkout.rechargeapps.com/r/checkout/${body?.token}`
          window.location.assign(rechargeCheckoutUrl)
        } else {
          throw new Error("No recharge checkout token")
        }
      } catch (err) {
        console.warn("Error creating recharge checkout", err)
      }
    },
    [callFunction, functions]
  )

  const processRechargeCheckout = useCallback(
    ({ lineItems, isSubscriptionProduct = false, isMainCart = false, isForced = false }) => {
      const isStaging = store?.id === "goodnessme-box-staging"

      // first map the checkout line item's productId onto the line items, since
      // subscription products are using product ids rather than variant ids
      const lineItemsWithProductIds = lineItems?.map((lineItem: CartLine) => ({
        quantity: lineItem?.quantity,
        variantId: lineItem?.merchandise?.id,
        attributes: lineItem?.attributes,
        productId: Number(decodeShopifyId(lineItem?.merchandise?.product?.id, "Product")),
      }))

      // next, normalise the necessary recharge fields into the line items, by checking the product ids
      const rechargeLineItems = lineItemsWithProductIds?.map((lineItem: any) => normaliseRechargeLineItem(lineItem))

      const checkoutContainsSubscriptionProducts = rechargeLineItems?.some(
        (lineItem: any) => lineItem?.charge_interval_frequency || lineItem?.order_interval_frequency || lineItem?.order_interval_unit
      )

      const shouldGoToRechargeCheckout = isSubscriptionProduct || (isMainCart && checkoutContainsSubscriptionProducts)

      if (isSubscriptionProduct && isStaging && isBrowser) {
        window?.alert("Cannot go to recharge checkout on the staging store")
        return { shouldGoToNormalCheckout: true }
      }

      // redirect to recharge on the following conditions:
      // - immediately after adding to cart, if the item is a subscription product, and not on staging
      // - on the main cart page, and the checkout items include a subscription product
      if (shouldGoToRechargeCheckout) {
        if (isSubscriptionProduct) {
          setPendingCheckout(true)
        } else if (pendingCheckout || isForced) {
          goToRechargeCheckout({ lineItems: rechargeLineItems })
        }
        return { shouldGoToNormalCheckout: false }
      } else {
        return { shouldGoToNormalCheckout: true }
      }
    },
    [goToRechargeCheckout, store?.id, isBrowser, decodeShopifyId, normaliseRechargeLineItem, pendingCheckout]
  )

  const getCustomerPortal = useCallback(
    async (customer: Customer) => {
      const storageCustomerPortalUrl = getStorage(keys.customerPortalUrl)

      // Set the localstorage portal url as state if it already exists
      if (!portalUrl && storageCustomerPortalUrl) return setPortalUrl(storageCustomerPortalUrl)

      const shopifyCustomerId = decodeShopifyId(customer?.id, "Customer")
      if (!shopifyCustomerId) return false

      try {
        const response = await callFunction(functions.rechargeGetCustomerPortal, {
          shopifyCustomerId,
        })
        if (response.status === "error") throw new Error("Error creating recharge customer portal URL", response)
        const { portal_url, expires_at }: { portal_url: string; expires_at: string } = response?.body || {}

        if (!portal_url || !expires_at) return false

        // @ts-ignore next-line
        const secToExpiry: number = (new Date(expires_at) - new Date()) / 1000
        const hourToExpiry = secToExpiry / 60 / 60
        const dayToExpiry = hourToExpiry / 24

        // // Store the portal url in localStorage to prevent refetching, and update state
        if (isBrowser) {
          setStorage(keys.customerPortalUrl, portal_url, dayToExpiry)
          setPortalUrl(portal_url)
        }
      } catch (err) {
        console.warn(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBrowser, customer?.id]
  )

  const checkSubscriptionText = useCallback((text?: string) => {
    return !!text?.toLowerCase()?.includes("subscription")
  }, [])

  useEffect(() => {
    getSubscriptionProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get recharge customer portal URL once customer is loaded
  useEffect(() => {
    const shouldCreateCustomerPortal = customer && isBrowser

    if (shouldCreateCustomerPortal) getCustomerPortal(customer)
  }, [customer, getCustomerPortal, isBrowser])

  const contextValue = useMemo<ContextProps>(
    () => ({
      checkIfSubscriptionProduct,
      subscriptionProducts,
      fetched,
      goToRechargeCheckout,
      processRechargeCheckout,
      portalUrl,
      checkSubscriptionText,
    }),
    [
      subscriptionProducts,
      fetched,
      checkIfSubscriptionProduct,
      goToRechargeCheckout,
      processRechargeCheckout,
      portalUrl,
      checkSubscriptionText,
    ]
  )

  return <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>
}

const useSubscriptionContext = (): ContextProps => ({ ...useContext(SubscriptionContext) } as ContextProps)

export { SubscriptionContext, SubscriptionProvider, useSubscriptionContext }
export type { ContextProps }
